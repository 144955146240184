import React, {useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WideReactionSelect from '../components/WideReactionSelect';
import styled from 'styled-components'
import { getProject, postCompletion, getParticipant } from '../services/apiCalls';
import { useLocation } from "react-router-dom";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const CompletedPage = () => {
    //get our query string
    let query = new URLSearchParams(useLocation().search);

    const [loading, setLoading] = React.useState('');
    const [main, setMain] = React.useState('');
    const [sub, setSub] = React.useState('');
    const [contentWidth, setContentWidth] = React.useState(0);

    const handleReactionClick = (unicode, img) => {
        //create the object using the id that was passed in on the querystring
        const completionObject = {
            projectId: query.get("p"),
            memberId: query.get("pa"),
            emoji: unicode,
        }
        postCompletion(completionObject).then((response) => {
            setSub('You have finished.')
            //get the project from the server
            getProject(query.get("p")).then((project) => {
                setLowerContent(<Header main={''} img={img.img} sub={'Thank you for your answer!'} finalText={project.data.contentProject.finalText} />);
            });
        }).catch((err) => {
            console.log("ERROR POSTING REACTION:    ", err)
        })
    };

    const [lowerContent, setLowerContent] = React.useState(<WideReactionSelect hideHeader={true} handleReactionClick={handleReactionClick} />);

    useEffect(() => {
        setLoading(true);
        //do we have a project and participant?
        //yes
        if (query.has("p") &&
            query.has("pa")) {
            //verify the participant for the project
            getParticipant(query.get("p"), query.get("pa"))
            .then((response) => {
                //did it come back as found?
                //no
                if (response.data.found === false) {
                    setUrl('/');
                }
                //did it come back as found?
                //yes
                else {
                    //get the project from the server
                    getProject(query.get("p")).then((project) => {
                        //fill in our variables we are using
                        setContentWidth(project.data.contentProject.contentWidth);
                        setMain('Which emoji represents your overall feeling about the video?');
                        setSub('');
                        setLoading(false);
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
                }
            });
        }
    }, []);

    return (
        loading === false 
        ?   <Box style={{ overflow: 'hidden'}}>
                <Header
                    main={main}
                    sub={sub}
                    img={null}
                    finalText={null}
                />
                <div style={{ overflow: 'hidden', minWidth: contentWidth + 'px' }}>
                    {lowerContent}
                </div>
                <Footer/>
            </Box>
        :   <Box>
                <Header
                    main={"Loading"}
                    sub={"We are loading your content"}
                />
                <Footer/>
            </Box>
    );
}

export default CompletedPage