import React, {useRef, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WideReactionSelect from '../components/WideReactionSelect';
import ReactPlayer from 'react-player';
import styled from 'styled-components'
import { getProject, postResponse, getParticipant } from '../services/apiCalls';
import { useLocation } from "react-router-dom";
import { Redirect } from 'react-router';

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const WideStudyPage = () => {
    //get our query string
    let query = new URLSearchParams(useLocation().search);
    const player = useRef(null);
    const [loading, setLoading] = React.useState('');

    const [videoURL, setVideoURL] = React.useState('');
    const [contentWidth, setContentWidth] = React.useState(0);
    const [contentHeight, setContentHeight] = React.useState(0);
    const [url, setUrl] = React.useState('');

    const switchContent = (status) => {
        //did it end?
        //yes
        if (status === "ended"){
            //send them to the wide completion page
            setUrl('/completed?p=' + query.get("p") + '&pa=' + query.get("pa"));
        } 
        //did it end?
        //no - started
        else if (status === "paused") {
            setLowerContent(
                <WideReactionSelect />
            )
        }
        //did it end?
        //no - started
        else if (status === "started") {
            setLowerContent(
                <WideReactionSelect handleReactionClick={handleReactionClick} />
            )
        }
    };

    const handleReactionClick = (unicode, img) => {
        const currentTime = player.current.getCurrentTime();
        //create the object using the id that was passed in on the querystring
        const reactionObject = {
            projectId: query.get("p"),
            memberId: query.get("pa"),
            videoURL,
            seconds: currentTime, 
            emoji: unicode,
        }
        postResponse(reactionObject).then((response) => {
            //do nothing
            console.log("!");
        }).catch((err) => {
            console.log("ERROR POSTING REACTION:    ", err)
        })
    };

    const [lowerContent, setLowerContent] = React.useState(<WideReactionSelect handleReactionClick={handleReactionClick} />);

    useEffect(() => {
        setLoading(true);
        //do we have a project and participant?
        //yes
        if (query.has("p") &&
            query.has("pa")) {
            //verify the participant for the project
            getParticipant(query.get("p"), query.get("pa"))
            .then((response) => {
                //did it come back as found?
                //no
                if (response.data.found === false) {
                    setUrl('/');
                }
                //did it come back as found?
                //yes
                else {
                    //get the project from the server
                    getProject(query.get("p")).then((project) => {
                        //fill in our variables we are using
                        setVideoURL(project.data.contentProject.contentURL);
                        setContentWidth(project.data.contentProject.contentWidth);
                        setContentHeight(project.data.contentProject.contentHeight);
                        setLoading(false);
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
                }
            });
        }
    }, []);

    return (
        //do we have a url?
        //yes
        url !== ''
        ?   <Redirect to={url}/>
        //do we have a url?
        //no
        :   loading === false 
            ?   <Box style={{ overflow: 'hidden'}}>
                    <ReactPlayer 
                        style={{ marginTop: 10 }}
                        width={contentWidth + 'px'}
                        height={contentHeight + 'px'}
                        ref={player} //wont work on a styled(ReactPlayer) component
                        controls={false}
                        onPlay={() => switchContent("started")}
                        onEnded={() => switchContent("ended")}
                        onPause={() => switchContent("paused")}
                        url={videoURL}
                    />
                    <div style={{ overflow: 'hidden', minWidth: contentWidth + 'px' }}>
                        {lowerContent}
                    </div>
                    <Footer/>
                </Box>
            :   <Box>
                    <Header
                        main={"Loading"}
                        sub={"We are loading your content"}
                        img={null}
                    />
                    <Footer/>
                </Box>
    );
}

export default WideStudyPage