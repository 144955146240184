import React from 'react';
import styled from 'styled-components';

const HeaderBox = styled.div`
  text-align: center;
  overflow: hidden;
`;
const TopLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const Heading = styled.h2`
  color: #000000;
  padding: 1rem;
  padding-bottom: 0;
  font-weight: bold;
  overflow: hidden;
`
const Subtext = styled.div`
  color: '#000000';  
  text-align: center;
  padding-bottom: 1rem;
  overflow: hidden;
`;

const StyledImg = styled.img`
  width: 4.0rem;
  margin-bottom: 4rem;
`;

const Header = (props) => {
  return (
    <HeaderBox style={{ overflow: 'hidden'}}>
        <TopLine style={{ overflow: 'hidden'}}>
            <Heading style={{ overflow: 'hidden'}}>{props.main}</Heading>
        </TopLine>
        <center style={{ overflow: 'hidden'}}>
        <StyledImg 
          src={props.img}
          style={{ display: props.img ? '' : 'none' }}
        />
        <Subtext key="sub" style={{ fontSize: '22px', overflow: 'hidden'}}>{props.sub}</Subtext>
        <Subtext key="finalText" style={{ display: props.finalText === null ? 'none' : '', fontSize: '22px', overflow: 'hidden'}}>
          To see the feedback for this video visit <a href="https://feelalytics.com/feelalytics-for-video/" target="_blank" rel="noopener noreferrer">feelalytics.com/feelalytics-for-video</a>.
        </Subtext>
        </center>
    </HeaderBox>
  );
};

export default Header;