import React from 'react';
import styled from 'styled-components'
import { negativeReactions, neutralReactions, positiveReactions } from './Reactions.jsx';
import ReactTooltip from 'react-tooltip';

const Box = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
`;

const StyledImg = styled.img`
    width: 3.6rem;
`;

const EmojiSectPositive = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(10px, auto);
    margin: 1rem;
    margin-right: -.2rem;
`;

const EmojiSectNeutral = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(10px, auto);
    margin: 1rem;
    margin-right: -.2rem;
`;

const EmojiSectNegative = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(10px, auto);
    margin: 1rem;
    margin-left: -.2rem;
`;

const WideReactionSelect = (props) => {

    let emotions = [];

    const sendReaction = (img, section, index) => {
        //do we have this function?
        //yes
        if (props.handleReactionClick) {
            const imgRotate = document.getElementById(section + index);
            //rotate it 40 degrees
            imgRotate.style.transform = 'rotate(40deg)';

            props.handleReactionClick(img.name, img);
            emotions.push(img.img);

            //rotate it back
            setTimeout(() => {
                imgRotate.style.transform = 'rotate(0deg)';
            }, 200);
        }
    };

    return (
        <>
            <div 
                key={'div1'}
                style={{ 
                    color: '#000000', 
                    textAlign: 'center', 
                    fontWeight: 'bold', 
                    fontSize: '14pt',
                    marginTop: 10,
                    display: props?.hideHeader ? 'none' : ''
                }}
            >
                Click anytime you feel something:
            </div>
            <Box 
                key={'box1'}
                style={{ marginTop: 5 }}
            >
                <div 
                    key={'divPositive'}
                    style={{ alignContent: 'center' }}
                >
                    <span 
                        key={'spanPositive'}
                        style={{ fontWeight: 'bold' }}
                    >
                        POSITIVE
                    </span>
                    <EmojiSectPositive>
                        {positiveReactions.map((img, index) => {
                        return  <>
                                    <a key={"apositive" + index} id={"apositive" + index} data-tip='' data-for={"positive" + index}>
                                        <StyledImg 
                                            key={"positive" + index} 
                                            id={"positive" + index}
                                            src={img.img} 
                                            alt={img.rollOver} 
                                            onClick={() => sendReaction(img, 'positive', index)}
                                        />
                                    </a>
                                    <ReactTooltip id={"positive" + index} key={"positive" + index} type='warning'>
                                        <span key={"spanpositive" + index}>{img.rollOver}</span>
                                    </ReactTooltip>
                                </>
                        })}
                    </EmojiSectPositive>
                </div>
                <div 
                    key={'divPositive1'}
                    style={{ marginTop: 35, marginBottom: 10, marginLeft: 20, marginRight: 0, width: 1, borderLeft: '1px solid' }}
                >
                </div>
                <div 
                    key={'divNeutral'}
                    style={{ alignContent: 'center' }}
                >
                    <span 
                        key={'spanNeutral'}
                        style={{ fontWeight: 'bold' }}
                    >
                        NEUTRAL
                    </span>
                    <EmojiSectNeutral>
                        {neutralReactions.map((img, index) => {
                        return  <>
                                    <a key={"aneutral" + index} id={"aneutral" + index} data-tip='' data-for={"neutral" + index}>
                                        <StyledImg 
                                            key={"neutral" + index} 
                                            id={"neutral" + index}
                                            src={img.img} 
                                            alt={img.rollOver} 
                                            onClick={() => sendReaction(img, 'neutral', index)}
                                        />
                                    </a>
                                    <ReactTooltip id={"neutral" + index} key={"neutral" + index} type='warning'>
                                        <span key={"spanneutral" + index}>{img.rollOver}</span>
                                    </ReactTooltip>
                                </>
                        })}
                    </EmojiSectNeutral>
                </div>
                <div 
                    key={'divNeutral1'}
                    style={{ marginTop: 35, marginBottom: 10, marginLeft: 20, marginRight: 20, width: 1, borderLeft: '1px solid' }}
                >
                </div>
                <div 
                    key={'divNegative'}
                    style={{ alignContent: 'center' }}
                >
                    <span 
                        key={'spanNegative'}
                        style={{ fontWeight: 'bold' }}
                    >
                        NEGATIVE
                    </span>
                    <EmojiSectNegative>
                        {negativeReactions.map((img, index) => {
                        return  <>
                                    <a key={"anegative" + index} id={"anegative" + index} data-tip='' data-for={"negative" + index}>
                                        <StyledImg 
                                            key={"negative" + index} 
                                            id={"negative" + index}
                                            src={img.img} 
                                            alt={img.rollOver} 
                                            onClick={() => sendReaction(img, 'negative', index)}
                                        />
                                    </a>
                                    <ReactTooltip id={"negative" + index} key={"negative" + index} type='warning'>
                                        <span key={"spannegative" + index}>{img.rollOver}</span>
                                    </ReactTooltip>
                                </>
                        })}
                    </EmojiSectNegative>
                </div>
            </Box>
        </>
    )
}

export default WideReactionSelect