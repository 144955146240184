import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PermissionsPage from '../pages/PermissionsPage';
import TallIntroPage from '../pages/TallIntroPage';
import WideIntroPage from '../pages/WideIntroPage';
import WideStudyPage from '../pages/WideStudyPage';
import TallStudyPage from '../pages/TallStudyPage';
import CompletedPage from '../pages/CompletedPage';

const Main = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/wintro">
            <WideIntroPage />
          </Route>
          <Route path="/wstudy">
            <WideStudyPage />
          </Route>
          <Route path="/tintro">
            <TallIntroPage />
          </Route>
          <Route path="/tstudy">
            <TallStudyPage />
          </Route>
          <Route path="/completed">
            <CompletedPage />
          </Route>
          <Route path="/">
            <PermissionsPage />
          </Route>
        </Switch>
    </Router>
    </>
  );
};

export default Main;
