import React from 'react';
import styled from 'styled-components';

const FooterStyle = styled.footer`
  right:0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0;
`;

const Box = styled.div`
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
`;

const Item = styled.div`
  margin-left: 0;
  margin-right: 0;
`;

const Footer = () => {
  return (
    <FooterStyle style={{ marginTop: 25, overflow: 'hidden' }}>
      <Box>
        <Item>
          Copyright 2022 - <a href="https://feelalytics.com" target="_blank">Feelalytics, Inc.</a> - All Rights Reserved
        </Item>
      </Box>
    </FooterStyle>
  );
};

export default Footer;