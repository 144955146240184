import React, {useRef, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TallReactionSelect from '../components/TallReactionSelect';
import styled from 'styled-components'
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player';
import { getProject, getParticipant } from '../services/apiCalls';
import { Redirect } from 'react-router';
import { Button } from 'antd';

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const TallIntroPage = () => {
    //get our query string
    let query = new URLSearchParams(useLocation().search);
    const player = useRef(null);
    const [loading, setLoading] = React.useState('');

    const [videoURL, setVideoURL] = React.useState('');
    const [nextWidth, setNextWidth] = React.useState(0);
    const [nextHeight, setNextHeight] = React.useState(0);
    const [contentWidth, setContentWidth] = React.useState(0);
    const [contentHeight, setContentHeight] = React.useState(0);
    const [contentType, setContentType] = React.useState('');
    const [url, setUrl] = React.useState('');

    const switchContent = (status) => {
        //did it end?
        //yes
        if (status === "ended") {
            //is this a tall video?
            //yes
            if (contentType == "video" &&
                nextWidth < nextHeight) {
                //send them to the tall video page
                setUrl('/tstudy?p=' + query.get("p") + '&pa=' + query.get("pa"));
            }
            //is this a tall video?
            //no
            else if (contentType == "video") {
                //send them to the wide video page
                setUrl('/wstudy?p=' + query.get("p") + '&pa=' + query.get("pa"));
            }
        } 
        //did it end?
        //no - started
        else if (status === "paused") {
            setSideContent(
                <TallReactionSelect />
            )
        }
        //did it end?
        //no - started
        else if (status === "started") {
            setSideContent(
                <TallReactionSelect handleReactionClick={handleReactionClick} />
            )
        }
    };

    const handleReactionClick = (unicode) => {
        console.log("!");
    };

    const [sideContent, setSideContent] = React.useState(<TallReactionSelect />);


    useEffect(() => {
        setLoading(true);
        //do we have a project and participant?
        //yes
        if (query.has("p") &&
            query.has("pa")) {
            //verify the participant for the project
            getParticipant(query.get("p"), query.get("pa"))
            .then((response) => {
                //did it come back as found?
                //no
                if (response.data.found === false) {
                    setUrl('/');
                }
                //did it come back as found?
                //yes
                else {
                    //get the project from the server
                    getProject(query.get("p")).then((project) => {
                        //fill in our variables we are using
                        setVideoURL(project.data.contentProject.contentURLIntro);
                        setContentWidth(project.data.contentProject.contentWidthIntro);
                        setContentHeight(project.data.contentProject.contentHeightIntro);
                        setNextWidth(project.data.contentProject.contentWidth);
                        setNextHeight(project.data.contentProject.contentHeight);
                        setContentType(project.data.contentProject.contentType);
                        setLoading(false);
                    }).catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
                }
            });
        }
    }, []);

    return (
        //are we loading?
        //yes
        loading === true 
        ?   <Box>
                <Header
                    main={"Loading"}
                    sub={"We are loading your content"}
                    img={null}
                />
            </Box>
        //are we loading?
        //no
        //do we have a url?
        //no
        : url === ''
            ?   <Box style={{ overflow: 'hidden' }}>
                    <div style={{ overflow: 'hidden', marginTop: 10, display: 'flex', flexDirection: 'row'}}>
                        <div style={{ overflow: 'hidden'}}>
                            <ReactPlayer 
                                style={{ marginTop: 32 }}
                                width={contentWidth + 'px'}
                                height={contentHeight + 'px'}
                                ref={player} //wont work on a styled(ReactPlayer) component
                                controls={false}
                                onPlay={() => switchContent("started")}
                                onEnded={() => switchContent("ended")}
                                onPause={() => switchContent("paused")}
                                url={videoURL}/>
                            <Button onClick={() => { switchContent("ended"); }} style={{ width: contentWidth + 'px'}}>
                                Skip intro video
                            </Button>
                        </div>
                        <div style={{ overflow: 'hidden', marginLeft: 50, marginTop: -6 }}>
                            {sideContent}
                        </div>
                    </div>
                    <Footer style={{ overflow: 'hidden' }}/>
            </Box>
            //do we have a url?
            //yes
            :   <Redirect to={url}/>
    );
}

export default TallIntroPage