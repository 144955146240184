import axios from "axios";

export const getParticipant = (projectId, participantId) => {
  //let url = "http://localhost:7071/api/contentfeedback/participant?projectId=" + projectId + "&memberId=" + participantId;
  let url = "https://mindfulappy.azurewebsites.net/api/contentfeedback/participant?projectId=" + projectId + "&memberId=" + participantId;

  return axios.get(url, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}

export const getProject = (projectId) => {
  //let url = "http://localhost:7071/api/contentfeedback/project?id=" + projectId;
  let url = "https://mindfulappy.azurewebsites.net/api/contentfeedback/project?id=" + projectId;

  return axios.get(url, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}

export const postCompletion = (data) => {
  //let url = "http://localhost:7071/api/contentfeedback/completion";
  let url = "https://mindfulappy.azurewebsites.net/api/contentfeedback/completion";
  
  return axios.post(url, data, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}

export const postResponse = (data) => {
  //let url = "http://localhost:7071/api/contentfeedback/response";
  let url = "https://mindfulappy.azurewebsites.net/api/contentfeedback/response";

  return axios.post(url, data, {
    headers: {
      SECRET: "A3B13FA1-94C8-40DF-8E21-383B0478DA5A",
      PASSPHRASE: "83951455947318790"
    }
  })
}
