import React, {useRef, useEffect} from 'react';
import Header from '../components/Header';
import styled from 'styled-components'
import { useLocation } from "react-router-dom";
import { getProject, getParticipant } from '../services/apiCalls';
import { Redirect } from 'react-router';

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
    overflow: hidden;
`;

const PermissionsPage = () => {
    //get our query string
    let query = new URLSearchParams(useLocation().search);

    const [url, setUrl] = React.useState('');
    const [header, setHeader] = React.useState('Loading');
    const [subHeader, setSubHeader] = React.useState('We are loading the content');

    useEffect(() => {
        //do we have a project and participant?
        //yes
        if (query.has("projectId") &&
            query.has("participantId")) {
            //verify the participant for thos project
            getParticipant(query.get("projectId"), query.get("participantId"))
            .then((response) => {
                //did it come back as found?
                //no
                if (response.data.found === false) {
                    setHeader('You Are Not Permitted');
                    setSubHeader('We could not verify you. Please contact your company representative.');
                }
                //did it come back as found?
                //yes
                else {
                    //get the project from the server
                    getProject(query.get("projectId"))
                    .then((project) => {
                        //do we have a tall intro page?
                        //yes
                        if (project.data.contentProject.contentTypeIntro == "video" &&
                            project.data.contentProject.contentURLIntro !== null &&
                            project.data.contentProject.contentWidthIntro < project.data.contentProject.contentHeightIntro) {
                            //send them to the tall intro video page
                            setUrl('/tintro?p=' + project.data.id + '&pa=' + query.get("participantId"));
                        }
                        //do we have a tall intro page?
                        //no - wide intro
                        else if (project.data.contentProject.contentTypeIntro == "video" &&
                                project.data.contentProject.contentURLIntro !== null &&
                                project.data.contentProject.contentWidthIntro > project.data.contentProject.contentHeightIntro) {
                            //send them to the wide intro video page
                            setUrl('/wintro?p=' + project.data.id + '&pa=' + query.get("participantId"));
                        }
                        //do we have an intro page?
                        //no - we have tall content
                        else if (project.data.contentProject.contentType == "video" &&
                                project.data.contentProject.contentWidth < project.data.contentProject.contentHeight) {
                            //send them to the tall study page
                            setUrl('/tstudy?p=' + project.data.id + '&pa=' + query.get("participantId"));
                        }
                        //do we have an intro page?
                        //no - we have wide content
                        else if (project.data.contentProject.contentType == "video") {
                            //send them to the wide study page
                            setUrl('/wstudy?p=' + project.data.id + '&pa=' + query.get("participantId"));
                        }
                    }).catch((err) => {
                        setHeader('You Are Not Permitted');
                        setSubHeader('Please contact your company representative.');
                    });
                }
            }).catch((err) => {
                setHeader('You Are Not Permitted');
                setSubHeader('Please contact your company representative.');
            });
        }
        //do we have a project and participant?
        //no
        else {
            setHeader('You Are Not Permitted');
            setSubHeader('Please contact your company representative.');
        }
    }, []);

    return (
        //do we have a url?
        //no
        url === ''
        ?   <Box>
                <Header 
                    main={header}
                    sub={subHeader}
                />
            </Box>  
        //do we have a url?
        //yes
        :   <Redirect to={url}/>
    );
}

export default PermissionsPage