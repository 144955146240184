import React from 'react';
import styled from 'styled-components'
import { negativeReactions, neutralReactions, positiveReactions } from './Reactions.jsx';
import ReactTooltip from 'react-tooltip';

const Box = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;    
`;

const StyledImg = styled.img`
    width: 3.6rem;
`;

const EmojiSect = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(10px, auto);
    margin: .5rem;
    margin-left: 0;
    margin-right: 0;
`;

const TallReactionSelect = (props) => {
    //create our list of emotions
    let emotions = [];

    const sendReaction = (img, section, index) => {
        //do we have this function?
        //yes
        if (props.handleReactionClick) {
            const imgRotate = document.getElementById(section + index);
            //rotate it 40 degrees
            imgRotate.style.transform = 'rotate(40deg)';

            props.handleReactionClick(img.name, img);
            emotions.push(img.img);

            //rotate it back
            setTimeout(() => {
                imgRotate.style.transform = 'rotate(0deg)';
            }, 200);
        }
    }

    return (
        <>
            <div style={{ 
                color: '#000000', 
                textAlign: 'left', 
                fontWeight: 'bold', 
                fontSize: '13pt',
                display: props.hideHeader ? 'none' : ''
            }}>
                Click anytime you feel something:
            </div>
            <div style={{ textAlign: 'center', width: 278, marginTop: '.5rem' }}>
                <Box style={{ display: 'grid', gridTemplateColumns: 1 }}>
                    <div style={{ width: 274, marginTop: 10, marginBottom: 20, height: 1, borderTop: '1px solid' }}></div>
                    <span style={{ fontWeight: 'bold' }}>
                        POSITIVE
                    </span>
                    <EmojiSect>
                        {positiveReactions.map((img, index) => {
                        return  <>
                                    <a key={"apositive" + index} id={"apositive" + index} data-tip='' data-for={"positive" + index}>
                                        <StyledImg 
                                            key={"positive" + index} 
                                            id={"positive" + index}
                                            src={img.img} 
                                            alt={img.rollOver} 
                                            onClick={() => sendReaction(img, 'positive', index)}
                                        />
                                    </a>
                                    <ReactTooltip id={"positive" + index} key={"positive" + index} type='warning'>
                                        <span key={"spanpositive" + index}>{img.rollOver}</span>
                                    </ReactTooltip>
                                </>
                        })}
                    </EmojiSect>
                    <div style={{ width: 274, marginTop: 20, marginBottom: 20, height: 1, borderTop: '1px solid' }}></div>
                    <span style={{ fontWeight: 'bold' }}>
                        NEUTRAL
                    </span>
                    <EmojiSect>
                        {neutralReactions.map((img, index) => {
                        return  <>
                                    <a key={"aneutral" + index} id={"aneutral" + index} data-tip='' data-for={"neutral" + index}>
                                        <StyledImg 
                                            key={"neutral" + index} 
                                            id={"neutral" + index}
                                            src={img.img} 
                                            alt={img.rollOver} 
                                            onClick={() => sendReaction(img, 'neutral', index)}
                                        />
                                    </a>
                                    <ReactTooltip id={"neutral" + index} key={"neutral" + index} type='warning'>
                                        <span key={"spanneutral" + index}>{img.rollOver}</span>
                                    </ReactTooltip>
                                </>
                        })}
                    </EmojiSect>
                    <div style={{ width: 274, marginTop: 20, marginBottom: 20, height: 1, borderTop: '1px solid' }}></div>
                    <span style={{ fontWeight: 'bold' }}>
                        NEGATIVE
                    </span>
                    <EmojiSect>
                        {negativeReactions.map((img, index) => {
                        return  <>
                                    <a key={"anegative" + index} id={"anegative" + index} data-tip='' data-for={"negative" + index}>
                                        <StyledImg 
                                            key={"negative" + index} 
                                            id={"negative" + index}
                                            src={img.img} 
                                            alt={img.rollOver} 
                                            onClick={() => sendReaction(img, 'negative', index)}
                                        />
                                    </a>
                                    <ReactTooltip id={"negative" + index} key={"negative" + index} type='warning'>
                                        <span key={"spannegative" + index}>{img.rollOver}</span>
                                    </ReactTooltip>
                                </>
                        })}
                    </EmojiSect>
                </Box>
            </div>
        </>
    )
}

export default TallReactionSelect