import image1 from '../images/1-grinning-face_1f600.png';
import image2 from '../images/2-rolling-on-the-floor-laughing_1f923.png';
import image3 from '../images/3-smiling-face-with-smiling-eyes_1f60a.png';
import image4 from '../images/4-grinning-face-with-one-large-and-one-small-eye_1f92a.png';
import image5 from '../images/5-red-heart_2764-fe0f.png';
import image6 from '../images/6-folded-hands_medium-skin-tone_1f64f-1f3fd_1f3fd.png';
import image7 from '../images/7-clapping-hands_1f44f.png';
import image8 from '../images/8-thumbs-up_1f44d.png';
import image9 from '../images/9-neutral-face_1f610.png';
import image10 from '../images/10-face-with-diagonal-mouth_1fae4.png';
import image11 from '../images/11-yawning-face_1f971.png';
import image12 from '../images/12-sleeping-face_1f634.png';
import image13 from '../images/13-face-with-monocle_1f9d0.png';
import image14 from '../images/14-lying-face_1f925.png';
import image15 from '../images/15-face-with-raised-eyebrow_1f928.png';
import image16 from '../images/16-confused-face_1f615.png';
import image17 from '../images/17-thumbs-down_1f44e.png';
import image18 from '../images/18-flushed-face_1f633.png';
import image19 from '../images/19-face-with-rolling-eyes_1f644.png';
import image20 from '../images/20-fearful-face_1f628.png';
import image21 from '../images/21-pouting-face_1f621.png';
import image22 from '../images/22-serious-face-with-symbols-covering-mouth_1f92c.png';
import image23 from '../images/23-exploding-head_1f92f.png';
import image24 from '../images/24-face-vomiting_1f92e.png';
import image25 from '../images/25-disappointed-but-relieved-face_1f625.png';
import image26 from '../images/26-face-screaming-in-fear_1f631.png';
import image27 from '../images/27-thinking-face_1f914.png';
import image28 from '../images/28-tired-face_1f62b.png';

export const obj1 = {
  img: image1,
  name: ':grinning:',
  rollOver: 'happy',
};

export const obj2 = {
  img: image2,
  name: ':rofl:',
  rollOver: 'hilarious',
};

export const obj3 = {
  img: image3,
  name: ':blush:',
  rollOver: 'positive',
};

export const obj4 = {
  img: image4,
  name: ':zany_face:',
  rollOver: 'crazy',
};

export const obj5 = {
  img: image5,
  name: ':heart:',
  rollOver: 'love',
};

export const obj6 = {
  img: image6,
  name: ':pray:',
  rollOver: 'thankful',
};

export const obj7 = {
  img: image7,
  name: ':clap:',
  rollOver: 'agree',
};

export const obj8 = {
  img: image8,
  name: ':thumbsup:',
  rollOver: 'good',
};

export const obj9 = {
  img: image9,
  name: ':neutral_face:',
  rollOver: 'neutral',
};

export const obj10 = {
  img: image10,
  name: ':face_with_diagonal_mouth:',
  rollOver: 'unsure',
};

export const obj11 = {
  img: image11,
  name: ':yawning_face:',
  rollOver: 'boring',
};

export const obj12 = {
  img: image12,
  name: ':sleeping:',
  rollOver: 'tired',
};

export const obj13 = {
  img: image13,
  name: ':face_with_monocle:',
  rollOver: 'skeptical',
};

export const obj14 = {
  img: image14,
  name: ':lying_face:',
  rollOver: 'disbelief',
};

export const obj15 = {
  img: image15,
  name: ':face_with_raised_eyebrow:',
  rollOver: 'confused',
};

export const obj16 = {
  img: image16,
  name: ':confused:',
  rollOver: 'meh',
};

export const obj17 = {
  img: image17,
  name: ':thumbsdown:',
  rollOver: 'bad',
};

export const obj18 = {
  img: image18,
   name: ':flushed:',
  rollOver: 'embarrassed',
};

export const obj19 = {
  img: image19,
  name: ':rolling_eyes:',
  rollOver: 'disapprove',
};

export const obj20 = {
  img: image20,
  name: ':fearful:',
  rollOver: 'afraid',
};

export const obj21 = {
  img: image21,
  name: ':rage:',
  rollOver: 'furious',
};

export const obj22 = {
  img: image22,
  name: ':face_with_symbols_on_mouth:',
  rollOver: 'enraged',
};

export const obj23 = {
  img: image23,
  name: ':exploding_head:',
  rollOver: 'shocked',
};

export const obj24 = {
  img: image24,
  name: ':face_vomiting:',
  rollOver: 'disgusted',
};

export const obj25 = {
  img: image25,
  name: ':disappointed_relieved:',
  rollOver: 'sad',
};

export const obj26 = {
  img: image26,
  name: ':scream:',
  rollOver: 'afraid',
};

export const obj27 = {
  img: image27,
  name: ':thinking:',
  rollOver: 'confused',
};

export const obj28 = {
  img: image28,
  name: ':tired_face:',
  rollOver: 'frustrated',
};

export const positiveReactions = [
  obj1,
  obj2,
  obj3,
  obj4,
  obj5,
  obj6,
  obj7,
  obj8,
];

export const neutralReactions = [
  obj9,
  obj10,
  obj11,
  obj12,
  obj27,
  obj14,
  obj15,
  obj16,
];

export const negativeReactions = [
  obj17,
  obj18,
  obj19,
  obj26,
  obj28,
  obj22,
  obj25,
  obj24,
]
